import moment from "moment";
import { IVersionElement } from "@monorepo/catalog/src/views/VersionCatalogView/types/versionElement";
import { DocumentTypesLabels } from "@monorepo/utils/src/types/documentTypes";
import { elementStatuses } from "@monorepo/utils/src/variables/projectsData/versionCatalogView/elementStatuses";

export const convertApiItemToUi = (item: IVersionElement): IVersionElement => {
  const statusObj = elementStatuses.find((status) => status.value === item.state);
  return {
    ...item,
    status: statusObj?.title || "",
    createAt: item.createAt ? moment(item.createAt).format("YYYY-MM-DD") : "",
    type: item.documentKindReferenceArea ? DocumentTypesLabels[item.documentKindReferenceArea as keyof typeof DocumentTypesLabels] : "",
    parent: item.parentVersion?.version || "",
  };
};
